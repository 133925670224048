import React, { Component } from 'react';
import { Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const TENANTS_WITH_EXT_COURSES = [1, 97, 1672, 1704];
const TENANTS_WITH_COMMUNICATIONS = [1, 97, 1704];
// const TENANTS_WITH_PREREGISTRATIONS = [1556];

class Nav extends Component {
  state = {
    selectedKeys: [window.location.pathname],
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ selectedKeys: [nextProps.path] });
  }

  renderAdminMenu = () => {
    if (this.props.loggedIn && this.props.user.superadmin) {
      return (
        <Menu.SubMenu
          title={
            <span>
              <Icon type="lock" style={{ color: 'gold' }} />
              <span>Administración</span>
            </span>
          }
        >
          <Menu.SubMenu
            title={
              <span>
                <Icon type="bar-chart" style={{ color: 'gold' }} />
                <span>Reportes</span>
              </span>
            }
          >
            <Menu.Item key="/admin/report">
              <Link to="/admin/report">
                <Icon type="file" style={{ color: 'gold' }} /> General
              </Link>
            </Menu.Item>
            <Menu.Item key="/admin/report/users">
              <Link to="/admin/report/users">
                <Icon type="file" style={{ color: 'gold' }} /> Usuarios
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="/admin/courses">
            <Link to="/admin/courses">
              <Icon type="form" style={{ color: 'gold' }} /> Diseño de cursos
            </Link>
          </Menu.Item>
        </Menu.SubMenu>
      );
    }
  };

  render() {
    return (
      <div>
        <Menu
          mode="inline"
          inlineIndent={12}
          theme="dark"
          selectedKeys={this.state.selectedKeys}
        >
          <Menu.Item key="/users">
            <Link to="/users">
              <Icon type="user" />
              <span>Usuarios</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/areas">
            <Link to="/areas">
              <Icon type="apartment" />
              <span>Áreas</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/positions">
            <Link to="/positions">
              <Icon type="idcard" />
              <span>Cargos</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/groups">
            <Link to="/groups">
              <Icon type="team" />
              <span>Grupos</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/courses">
            <Link to="/courses">
              <Icon type="read" />
              <span>Cursos</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/formation-paths">
            <Link to="/formation-paths">
              <Icon type="fork" />
              <span>Planes de formación</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/organizational-units">
            <Link to="/organizational-units">
              <Icon type="apartment" />
              <span>Unidades de negocio</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/certifications">
            <Link to="/certifications">
              <Icon type="audit" />
              <span>Certificaciones</span>
            </Link>
          </Menu.Item>
          {/* {TENANTS_WITH_PREREGISTRATIONS.indexOf(this.props.user.tenant_id) >=
            0 && ( */}
          <Menu.Item key="/preregistrations">
            <Link to="/preregistrations">
              <Icon type="book" />
              <span>Prerregistros</span>
            </Link>
          </Menu.Item>
          {/* )} */}
          {TENANTS_WITH_COMMUNICATIONS.indexOf(this.props.user.tenant_id) >=
            0 && (
            <Menu.Item key="/communications">
              <Link to="/communications">
                <Icon type="notification" />
                <span>Comunicaciones</span>
              </Link>
            </Menu.Item>
          )}
          {TENANTS_WITH_EXT_COURSES.indexOf(this.props.user.tenant_id) >= 0 && (
            <Menu.Item key="/dashboard">
              <Link to="/dashboard">
                <Icon type="dashboard" />
                <span>Indicadores</span>
              </Link>
            </Menu.Item>
          )}
          {TENANTS_WITH_EXT_COURSES.indexOf(this.props.user.tenant_id) >= 0 && (
            <Menu.Item key="/external-courses">
              <Link to="/external-courses">
                <Icon type="file-sync" />
                <span>Cursos externos</span>
              </Link>
            </Menu.Item>
          )}
          {TENANTS_WITH_EXT_COURSES.indexOf(this.props.user.tenant_id) >= 0 && (
            <Menu.Item key="/instructors">
              <Link to="/instructors">
                <Icon type="highlight" />
                <span>Formadores</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.SubMenu
            title={
              <span>
                <Icon type="bar-chart" />
                <span>Reportes</span>
              </span>
            }
          >
            <Menu.Item key="/reports/routes">
              <Link to="/reports/routes">
                <Icon type="file" />
                Planes de formación
              </Link>
            </Menu.Item>
            <Menu.Item key="/reports/courses">
              <Link to="/reports/courses">
                <Icon type="file" />
                Cursos
              </Link>
            </Menu.Item>
            <Menu.Item key="/reports/modules">
              <Link to="/reports/modules">
                <Icon type="file" />
                Módulos
              </Link>
            </Menu.Item>
            <Menu.Item key="/reports/responses">
              <Link to="/reports/responses">
                <Icon type="file" />
                Respuestas curso
              </Link>
            </Menu.Item>
            {/* <Menu.Item key='/reports/activity'>
              <Link to='/reports/activity'>
                <Icon type='file' />
                Usuarios activos
              </Link>
            </Menu.Item> */}
          </Menu.SubMenu>
          {this.renderAdminMenu()}
        </Menu>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.auth, path: state.routing.location.pathname };
}

export default connect(mapStateToProps)(Nav);
