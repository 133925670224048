import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Table, Badge, Input, Avatar, message, Modal, Button } from 'antd';
import { Link } from 'react-router-dom';
import PreregistrationsService from '../core/services/preregistrations';

import profile_pic from '../../assets/profile_pic.png';
import './preregistrations.page.css';

const confirm = Modal.confirm;

class PreregistrationsPage extends PureComponent {
  state = {
    selectedRowKeys: [],
    data: [],
    custom_fields: [],
    sort: {},
    pagination: {},
    query: null,
    loading: false,
  };

  canEdit = () => {
    return this.props.auth.user.role === 'admin';
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        title: 'Nombres',
        dataIndex: 'full_name',
        sorter: true,
        render: (val, record) => {
          return (
            <Link to={'/preregistrations/' + record.id}>
              <div className="nameContainer">
                <Badge
                  dot
                  offset={[-4, 4]}
                  title={record.disabled ? 'Deshabilitado' : 'Habilitado'}
                  status={record.disabled ? 'default' : 'success'}
                >
                  <Avatar
                    style={{ border: '1px solid #ddd' }}
                    src={record.avatar ? record.avatar : profile_pic}
                  />
                </Badge>
                <div className="nameAndEmail">
                  <div title={val}>{val}</div>
                  <div title={record.email}>{record.email}</div>
                </div>
              </div>
            </Link>
          );
        },
      },
      {
        title: 'Identificación',
        dataIndex: 'identification_number',
        ellipsis: true,
        sorter: true,
      },
      {
        title: 'Area',
        dataIndex: 'area_name',
        ellipsis: true,
        sorter: true,
      },
      {
        title: 'Cargo',
        dataIndex: 'position_name',
        ellipsis: true,
        sorter: true,
      },

      {
        title: 'Ubicación',
        dataIndex: 'location',
        ellipsis: true,
        sorter: true,
      },
    ];
  }

  queryPreregistrations = async () => {
    try {
      this.setState({ loading: true });
      const result = await PreregistrationsService.list(
        this.state.query,
        10,
        10 * ((this.state.pagination.current ?? 1) - 1),
        this.state.sort.field,
        this.state.sort.order
      );

      this.setState((prev) => ({
        ...prev,
        data: result.results,
        customFields: result.custom_fields,
        pagination: {
          ...prev.pagination,
          pageSize: result.pagination.limit,
          total: result.pagination.total,
        },
      }));

      this.props.layout.changeTitle(
        `Preregistros (${result.pagination.total})`
      );
    } catch (err) {
      console.log(err);
      message.error('Ocurrio un error al consultar los prerregistros');
    } finally {
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    this.queryPreregistrations();
  }

  handleTableChange = async (pagination, _, sorter) => {
    await this.setState({ pagination, sort: sorter }, () =>
      this.queryPreregistrations()
    );
  };

  approveBatch = async () => {
    try {
      this.setState({ loading: true });
      await PreregistrationsService.approveBulk(this.state.selectedRowKeys);

      this.doSearch(this.state.query);
      this.setState({ selectedRowKeys: [] });
      message.success(`Preregistros aprobados`);
    } catch (err) {
      console.log(err);
      message.error('Ocurrio un error al aprobar los prerregistros');
    } finally {
      this.setState({ loading: false });
    }
  };

  rejectBatch = async () => {
    try {
      this.setState({ loading: true });
      await PreregistrationsService.rejectBulk(this.state.selectedRowKeys);

      this.doSearch(this.state.query);
      this.setState({ selectedRowKeys: [] });
      message.success(`Preregistros rechazados`);
    } catch (err) {
      console.log(err);
      message.error('Ocurrio un error al rechazar los prerregistros');
    } finally {
      this.setState({ loading: false });
    }
  };

  handleAction = (action) => {
    switch (action) {
      case 'approve':
        confirm({
          title: `¿Estás seguro que quieres aprobar los prerregistros seleccionados?`,
          content:
            'Se crearán usuarios en Intrena para cada uno de los prerregistros',
          okText: 'Aceptar',
          cancelText: 'Cancelar',
          onOk: () => {
            this.approveBatch();
          },
        });
        break;
      case 'reject':
        confirm({
          title: `¿Estás seguro que quieres rechazar los prerregistros seleccionados?`,
          content:
            'Se enviará un correo a los correos de los prerregistros rechazados, y tendrán que solicitar de nuevo el registro.',
          okText: 'Aceptar',
          cancelText: 'Cancelar',
          onOk: () => {
            this.rejectBatch();
          },
        });
        break;
      default:
        console.warn('unknown action');
    }
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  getCheckboxProps = (record) => {
    return {
      disabled: false,
      name: record.id.toString(),
    };
  };

  doSearch = (value) => {
    this.setState(
      { query: value, pagination: { ...this.state.pagination, current: 1 } },
      () => this.queryPreregistrations()
    );
  };

  render() {
    const { data, selectedRowKeys, loading } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: this.getCheckboxProps,
      columnWidth: 32,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <div className="content-internal">
        <div className="first-row">
          <Input.Search
            placeholder="Buscar por nombres, identificación o correo (Ej. Juan Pérez) ..."
            onSearch={this.doSearch}
          />
          <div style={{ flexShrink: 0 }}>
            {hasSelected && (
              <Button.Group>
                <Button
                  icon="check"
                  onClick={() => this.handleAction('approve')}
                  type="primary"
                >
                  Aprobar seleccionados
                </Button>
                <Button
                  icon="close"
                  onClick={() => this.handleAction('reject')}
                >
                  Rechazar seleccionados
                </Button>
              </Button.Group>
            )}
          </div>
        </div>

        <Table
          loading={loading}
          size="medium"
          className="striped-rows"
          onChange={this.handleTableChange}
          pagination={this.state.pagination}
          rowKey={(u) => u.id}
          rowSelection={this.canEdit() ? rowSelection : undefined}
          columns={this.columns}
          dataSource={data}
          locale={{
            emptyText: 'No tiene preregistros asignados en el momento',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.users, auth: state.auth };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreregistrationsPage);
