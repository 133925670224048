import BaseService from './baseService';

export default class PreregistrationsService extends BaseService {
  static async list(query, limit, skip, sortField, sortOrder) {
    return await super.request(
      `/preregistrations?limit=${limit}&skip=${skip}&sortField=${
        sortField ?? ''
      }&sortOrder=${sortOrder ?? ''}&q=${
        query ? encodeURIComponent(query) : ''
      }`
    );
  }

  static async find(preregId) {
    return await super.request(`/preregistrations/${preregId}`);
  }

  static async approve(id, values) {
    return await super.request(
      `/preregistrations/${id}/approve`,
      'PUT',
      values
    );
  }

  static async approveBulk(ids) {
    return await super.request(
      `/preregistrations/_bulk/${JSON.stringify(ids)}/approve`,
      'PUT'
    );
  }

  static async rejectBulk(ids) {
    return await super.request(
      `/preregistrations/_bulk/${JSON.stringify(ids)}/reject`,
      'PUT'
    );
  }

  static async reject(id) {
    return await super.request(`/preregistrations/${id}/reject`, 'PUT');
  }

  static async emailExists(tid, email) {
    const resp = await super.request(
      `/preregistrations/${tid}/email?q=${email}`
    );
    return resp.exists_user;
  }

  static async mobileExists(tid, mobile) {
    const resp = await super.request(
      `/preregistrations/${tid}/mobile?q=${mobile}`
    );
    return resp.exists_user;
  }

  static async getAreas(tid) {
    return await super.request(`/preregistrations/${tid}/areas`);
  }

  static async getPositions(tid) {
    return await super.request(`/preregistrations/${tid}/positions`);
  }

  static async getLocations(tid) {
    return await super.request(`/preregistrations/${tid}/locations`);
  }

  static async getExtendedFieldValues(tid, index) {
    return await super.request(
      `/preregistrations/${tid}/extended_field_values?index=${index}`
    );
  }
}
