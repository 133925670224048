import React, { PureComponent } from 'react';
import { Modal, List, Checkbox } from 'antd';
import client from '../core/services/groups';

class AddCourseModal extends PureComponent {
  state = {
    courses: [],
    selectedItems: [],
    error: null,
    fetching: false,
  };

  async componentDidMount() {
    await this.queryCourses();
  }

  async componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) await this.queryCourses();
  }

  queryCourses = async () => {
    try {
      this.setState({ selectedItems: [], fetching: true });
      var resp = await client.listCoursesAvailable(this.props.groupId); //TODO: Paginación
      this.setState({ courses: resp, fetching: false });
    } catch (ex) {
      this.setState({ error: ex, fetching: false });
    }
  };

  toggleItem = (item) => {
    var idx = this.state.selectedItems.indexOf(item.id);
    var newArr = [...this.state.selectedItems];
    if (idx >= 0) {
      newArr.splice(idx, 1);
    } else {
      newArr.push(item.id);
    }

    this.setState({ selectedItems: newArr });
  };

  onOk = () => {
    this.props.onOk(this.state.selectedItems);
  };

  render() {
    const { visible, onCancel, confirmLoading } = this.props;

    return (
      <Modal
        title="Asociar cursos"
        visible={visible}
        onOk={this.onOk}
        confirmLoading={confirmLoading}
        onCancel={onCancel}
        okText="Guardar"
        cancelText="Cancelar"
      >
        <List
          bordered
          loading={this.state.fetching}
          locale={{
            emptyText: this.state.error
              ? 'Ocurrió un error al cargar los cursos'
              : 'No hay cursos para asignar',
          }}
          dataSource={this.state.courses}
          renderItem={(item) => (
            <List.Item>
              <Checkbox
                checked={(this.state.selectedItems || []).indexOf(item.id) >= 0}
                onChange={() => this.toggleItem(item)}
              >
                {item.name}
              </Checkbox>
            </List.Item>
          )}
        />
      </Modal>
    );
  }
}

export default AddCourseModal;
